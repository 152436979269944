export function getLabel(item) {
  const product = {
    ...item,
    ...!item.productParentName && !!item.productVariant && {
      ...item.productVariant,
      productParentName: item.product.name,
      productParentId: item.productId,
      productId: item.productVariant.productId,
    }
  };

  const productName = product?.name || product?.product?.name || "";

  if (!!product.productParentName && (productName).includes(product.productParentName)) {
    return productName;
  }

  const precede = `${!!product.productParentName ? `${product.productParentName} (` : ""}`;
  const succeed = `${!!product.productParentName ? ")" : ""}`;
  return `${precede}${productName}${succeed}`;
}
